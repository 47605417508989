@import 'core/index.css';

.button {
  @include button-primary;
  cursor: wait;
}

.button[data-pico-status] {
  cursor: pointer;
}

.button[data-pico-status='paying'] {
  display: none;
}

.standard-height {
  height: rem(60);
  max-height: 100%;
}

.full-height {
  height: 100%;
}

.standard-width {
  display: inline-flex;
  flex-shrink: 1;
  width: rem(240);
}

.full-width {
  display: flex;
  flex-shrink: 0;
  width: 100%;

  &::before {
    background: rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    content: '';
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &::after {
    animation: pico-loading-spinner 0.6s linear infinite;
    border: 2px solid #ccc;
    border-radius: 50%;
    border-top-color: #333;
    box-sizing: border-box;
    content: '';
    height: 20px;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
  }

  &[data-pico-status]::before,
  &[data-pico-status]::after {
    display: none;
  }
}

@keyframes pico-loading-spinner {

  to {
    transform: rotate(360deg);
  }
}
